import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { v4 as uuidv4 } from 'uuid';

import CoursesImageCard from './CoursesImageCard';
import { isEmptyOrSpaces } from "./Common";

export default class CarouselSection extends React.Component {
    renderTitle(textColor) {
        const title = this.props.title;

        if (!isEmptyOrSpaces(title)) {
            return (
                <Typography classes={{ root: 'bestCoursesRow' }} gutterBottom variant="h6" style={{ color: textColor }} >
                    {title}
                </Typography>
            );
        }

        return;
    }

    renderSubTitle(textColor) {
        const subTitle = this.props.subTitle;

        if (!isEmptyOrSpaces(subTitle)) {
            return (
                <Typography classes={{ root: 'bestCoursesSubtitle' }} gutterBottom variant="h6" style={{ color: textColor }} >
                    {subTitle}
                </Typography>
            );
        }
        return;
    }

    renderItems() {
        const items = this.props.items;
        const height = this.props.height > 0 ? this.props.height : 100;
        const width = this.props.width;

        return (
            <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center">
                {
                    items.map
                        (
                            (item, i) => {
                                const key = uuidv4();
                                return (
                                    <Grid item key={key} xs={10}>
                                        <CoursesImageCard key={key} title={item.title} image={item.image} height={height} width={width} styleClass='cardroot' data={item} />
                                    </Grid>
                                );
                            }
                        )
                }
            </Grid>
        );
    }

    render() {
        const items = this.props.items;
        const background = isEmptyOrSpaces(this.props.background) ? "#f2f1f6" : this.props.background;
        const textColor = isEmptyOrSpaces(this.props.textColor) ? "#000000" : this.props.textColor;

        if (items !== undefined && items.length > 0) {
            const key = uuidv4();
            return (
                <div key={key} style={{ background: background }}>
                    {this.renderItems()}
                    {this.renderSubTitle(textColor)}
                </div>
            );
        }

        return;
    }
}