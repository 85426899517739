import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { v4 as uuidv4 } from 'uuid';
import CarouselSection from './components/CarouselSection';
import './App.css';
import { isEmpty, isEmptyOrSpaces, isUndefinedOrNull, postMessage, fetchData } from "./components/Common";
import RowSection from './components/RowSection';

let compRenders = [];

const RenderAction = (order, fun) => { return { order: order, fun: fun } }

function getData() {
  const q = new URLSearchParams(window.location.search);
  const isDev = q.get("dev") === 'true';
  const d = localStorage.getItem('mobile_data');

  var data;

  if (d === null || d === undefined) {
    var file = q.get("c");

    if (!isEmptyOrSpaces(file)) {
      file = "data/" + file + ".json";
    }
    else {
      file = q.get("a");

      if (!isEmptyOrSpaces(file)) {
        file = "ads/" + file + ".json";
      }
      else {
        file = "data/test.json";
      }
    }
    data = isDev ? "{\"AppId\":\"1\",\"RegionName\":\"FL\",\"Lat\":28.549276,\"Lon\":-81.390821,\"UserId\":\"ffrrfrfrfrfr-frfrfrww-dsds\",\"DataUrl\":\"" + file + "\",\"IsBusyTrueTag\":\"#isbusy:true\",\"IsBusyFalseTag\":\"#isbusy:false\",\"ErrorTag\":\"#error\"}" : "{}";
  }
  else {
    data = isEmptyOrSpaces(d) ? "{}" : d;
  }

  return data;
}

const deviceData = JSON.parse(getData());

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = null;
  }

  async componentDidMount() {
    try {
      postMessage(deviceData.IsBusyTrueTag)
      const response = await fetchData('config.json');
      const config = await response.json();

      const q = new URLSearchParams(window.location.search);
      const tabId = q.get("tabId");

      const appQuicklinksEndpoint = `${config.apiUrl}/api/v1/applications/${deviceData.AppId}/quicklinks`;
      const appQuicklinksPromise = fetchData(appQuicklinksEndpoint);
      
      let quicklinks = null;

      if (!isEmptyOrSpaces(tabId)){
        const tabQuicklinksEndpoint = `${config.apiUrl}/api/v1/homepage/tabs/${tabId}/quicklinks`;
        const tabQuicklinksResponse = await fetchData(tabQuicklinksEndpoint);
        quicklinks = await tabQuicklinksResponse.json();
      }
      
      if (isUndefinedOrNull(quicklinks) || isUndefinedOrNull(quicklinks.rows) || isEmpty(quicklinks.rows.items)) {
        const appQuicklinksResponse = await appQuicklinksPromise;
        quicklinks = await appQuicklinksResponse.json();
      }

      this.setState(quicklinks);
      postMessage(deviceData.IsBusyFalseTag)
    }
    catch (e) {
      postMessage("#error")
    }
  }

  isGeoAllowed(regions) {
    if (isEmpty(regions)) {
      return true;
    }

    var r = deviceData.RegionName;

    if (isEmptyOrSpaces(r)) {
      return false;
    }

    r = r.toLowerCase().trim();

    for (var i in regions) {
      var region = regions[i];

      if (region !== null && region !== undefined) {
        region = region.toLowerCase().trim();
        if (region === r) {
          return true;
        }
      }
    }

    return false;
  }

  filterList(list) {
    if (isEmpty(list)) {
      return list;
    }

    var newList = [];

    for (var i in list) {
      var obj = list[i];
      var enabled = obj.enabled === null || obj.enabled === undefined || obj.enabled;

      var isGeoAllowed = obj.regions === null || obj.regions === undefined || this.isGeoAllowed(obj.regions);
      if (enabled && isGeoAllowed) {
        var oClone = Object.assign({}, obj)
        newList.push(oClone);
      }
    }

    return newList;
  }

  renderBestCoursesItems() {
    const bc = this.state.bestCourses;

    if (bc === undefined) {
      return;
    }

    bc.items = this.filterList(bc.items);

    if (isEmpty(bc.items)) {
      return;
    }

    const key = uuidv4();
    return (
      <CarouselSection key={key} title={bc.title} subTitle={bc.subTitle} background={bc.background} height={100} width={250} items={bc.items} textColor={bc.textColor} />
    );
  }

  renderDealItems() {
    const bc = this.state.deals;

    if (bc === undefined) {
      return;
    }

    bc.items = this.filterList(bc.items);

    if (isEmpty(bc.items)) {
      return;
    }

    const key = uuidv4();
    return (
      <CarouselSection key={key} title={bc.title} subTitle={bc.subTitle} background={bc.background} height={80} width='80%' items={bc.items} textColor={bc.textColor} />
    );
  }

  renderDestinationsItems() {
    const bc = this.state.destinations;

    if (bc === undefined) {
      return;
    }

    bc.items = this.filterList(bc.items);

    if (isEmpty(bc.items)) {
      return;
    }

    const key = uuidv4();
    return (
      <CarouselSection key={key} title={bc.title} subTitle={bc.subTitle} background={bc.background} height={150} width={200} items={bc.items} textColor={bc.textColor} />
    );
  }

  renderAdsItems() {
    const bc = this.state.ads;

    if (bc === undefined) {
      return;
    }

    bc.items = this.filterList(bc.items);

    if (isEmpty(bc.items)) {
      return;
    }

    var width = bc.width;

    if (isEmptyOrSpaces(width)) {
      width = "80%";
    }

    width = bc.items.length > 1 ? width : "90%";


    var height = bc.height > 0 ? bc.height : 80;
    const key = uuidv4();
    return (
      <CarouselSection key={key} title={bc.title} subTitle={bc.subTitle} background={bc.background} height={height} width={width} items={bc.items} textColor={bc.textColor} />
    );
  }

  renderPromotionsItems() {
    const bc = this.state.promotions;

    if (bc === undefined) {
      return;
    }

    bc.items = this.filterList(bc.items);

    if (isEmpty(bc.items)) {
      return;
    }

    var width = bc.items.length > 1 ? "80%" : "90%";
    const key = uuidv4();
    return (
      <Card key={key} classes={{ root: "cardroot" }} style={{ width: width  }}>
        <CardMedia classes={{ root: "containImage" }} component="img" height={155} image={bc.items[0].image}/>
      </Card>
    );
  }

  renderRowItems() {
    const bc = this.state.rows;

    if (bc === undefined) {
      return;
    }

    bc.items = this.filterList(bc.items);

    if (isEmpty(bc.items)) {
      return;
    }

    const key = uuidv4();
    return (
      <RowSection
        key={key}
        items={bc.items}
        />
    );
  }

  renderFooter() {
    const bc = this.state.footer;

    if (bc === undefined || bc.image === undefined) {
      return;
    }

    const key = uuidv4();
    const imageSrc = `${bc.image}&cache=${key}`;
    return (
      <div key={key} className="image-section">
        <img src={imageSrc} alt="Footer"/>
      </div>
    );
  }

  addRenderAction(obj, f) {
    if (obj === undefined || obj === null) {
      return;
    }
    if (!Number.isInteger(obj.order) || obj.order < 0 || obj.enabled !== undefined) {
      const enabled = obj.enabled === null || obj.enabled;
      if (!enabled) {
        return;
      }
    }

    compRenders.push(RenderAction(obj.order, f));
    compRenders = compRenders.sort((a, b) => a.order - b.order);
  }

  render() {
    const isWaitingForData = this.state === null;

    if (isWaitingForData) {
      return (
        <div className="centered animate-flicker">
          Loading...
        </div>
      );
    }

    this.addRenderAction(this.state.promotions, this.renderPromotionsItems);
    this.addRenderAction(this.state.deals, this.renderDealItems);
    this.addRenderAction(this.state.bestCourses, this.renderBestCoursesItems);
    this.addRenderAction(this.state.destinations, this.renderDestinationsItems);
    this.addRenderAction(this.state.ads, this.renderAdsItems);
    this.addRenderAction(this.state.rows, this.renderRowItems);
    this.addRenderAction(this.state.footer, this.renderFooter);

    return (
      <div className="appContainer">
        {compRenders.map((item) => item.fun.bind(this)())}
      </div>
    );
  }
}