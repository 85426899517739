import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { isEmptyOrSpaces, postMessage } from "./Common";

export default class RowCell extends React.Component {

  getClassName(cell) {
    let className = "rowcell";
    if (!isEmptyOrSpaces(cell.image)){
      className += " with-image";
    }
    if (!isEmptyOrSpaces(cell.title) || !isEmptyOrSpaces(cell.body)){
      className += " with-text";
      if (!isEmptyOrSpaces(cell.title)){
        className += " with-title";
      }
      if (!isEmptyOrSpaces(cell.body)){
        className += " with-body";
      }
    }
    return className;
  }

  renderImage(image) {
    if (isEmptyOrSpaces(image)) {
      return;
    } 

    return (
      <div className="card-image-container">
        <CardMedia
              data-src={image}
              component="img"
              image={image}
              onLoad={(event) => this.onLoad(event)}
            />
      </div>
    );
  }

  renderContent(title, body) {
    if (isEmptyOrSpaces(title) && isEmptyOrSpaces(body)) {
      return;
    } 
    return (
      <CardContent>
        {!isEmptyOrSpaces(title) && (
          <Typography
            gutterBottom
            align="center"
            classes={{ root: "cardTitle" }}
          >
            {title}
          </Typography>
        )}
        {!isEmptyOrSpaces(body) && (
          <Typography
            gutterBottom
            align="center"
            variant="body2"
            color="textSecondary"
            style={{whiteSpace: 'pre-line'}}
          >
            {body}
          </Typography>
        )}
      </CardContent>
    );
  }

  onLoad(e) {
    e.target.removeAttribute('data-src');
  }

  postData(data) {
    const rawData = JSON.stringify(data);
    postMessage(rawData);
  }

  render() {
    const title = this.props.title;
    const body = this.props.data.body;
    const image = this.props.image;
    const data = this.props.data;
    const className = this.getClassName(data);

    // (juan) disabling the ripple animation was necessary as it was causing issues when displaying on Android webview: keeping darker when losing focus at the middle of the animation
    return (
      <Card classes={{ root: className }} style={{}}>
        <CardActionArea onClick={(event) => this.postData(data)} disableRipple={true}>
          {(isEmptyOrSpaces(image) && isEmptyOrSpaces(title) && isEmptyOrSpaces(body)) && (<CardContent></CardContent>)}
          {this.renderImage(image)}
          {this.renderContent(title, body)}
        </CardActionArea>
      </Card>
    );
  }
}
