import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { isEmptyOrSpaces, postMessage } from "./Common";

export default class ImageCard extends React.Component {

  renderContent(title) {
    if (!isEmptyOrSpaces(title)) {
      return (
        <CardContent>
          <Typography gutterBottom classes={{ root: 'cardTitle' }}>
            {title}
          </Typography>
        </CardContent>
      );
    }
    return;
  }

  onLoad(e, data) {
    e.target.removeAttribute('data-src');
    var d = Object.assign({}, data);
    d.isImpression = true;
    this.postData(d);
  }

  postData(data) {
    const rawData = JSON.stringify(data);
    postMessage(rawData);
  }

  render() {
    const title = this.props.title;
    const image = this.props.image;
    const height = this.props.height;
    const width = this.props.width;
    const styleClass = this.props.styleClass;
    const data = this.props.data;

    return (
      <Card classes={{ root: styleClass }} style={{ width: width }} >
        <CardActionArea onClick={event => this.postData(data)}>
          <CardMedia
            data-src={image}
            component="img"
            height={height}
            image={image}
            onLoad={event => this.onLoad(event, data)}
          />
          {this.renderContent(title)}
        </CardActionArea>
      </Card>
    );
  }
}