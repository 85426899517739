export function isEmptyOrSpaces(str) {
    return str === undefined || str === null  || str.match(/^ *$/) !== null;
}

export function isUndefinedOrNull(str) {
    return str === undefined || str === null;
}

export function isEmpty(arr) {
    return (!Array.isArray(arr) || !arr.length);
}

export function postMessage(data) {
    console.log(data);
}

export function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );
}

export function fetchData(endpoint) {
    return fetch(endpoint, {
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
        }
    });
}