import React from "react";
import Grid from "@material-ui/core/Grid";
import { v4 as uuidv4 } from "uuid";
import { isEmptyOrSpaces } from "./Common";
import RowCell from "./RowCell";

export default class RowSection extends React.Component {

  renderItems() {
    const items = this.props.items;
    const height = this.props.height > 0 ? this.props.height : 100;
    const width = this.props.width;

    return (
      <Grid
        container
        spacing={0}
        alignItems="flex-start"
        justifycontent="flex-start"
      >
        {items.map((row, i) => {
          const cellWidthProportion = 12/row.items.length;
          const rowKey = uuidv4();

          return (
            <Grid key={rowKey} container spacing={2} classes={{root: "row"}}>
              {row.items.map((cell, i) => {
                const cellKey = uuidv4();
                return (
                  <Grid key={cellKey} item xs={cellWidthProportion}>
                    <RowCell
                      title={cell.title}
                      image={cell.image}
                      height={height}
                      width={width}
                      data={cell}
                    />
                  </Grid>
                );
              })}{" "}
            </Grid>
          );
        })}
      </Grid>
    );
  }

  render() {
    const items = this.props.items;
    const background = isEmptyOrSpaces(this.props.background)
      ? "#f2f1f6"
      : this.props.background;

    if (items !== undefined && items.length > 0) {
      const key = uuidv4();
      return (
        <div key={key} style={{ background: background }}>
          {this.renderItems()}
        </div>
      );
    }

    return;
  }
}
